import styled, { css } from "styled-components";
import {
    Container,
    Section,
    Columns,
    Column,
    Title,
    Subtitle,
    Heading,
    Box,
} from "../../sdk/Layout";
import { Modal } from "../../sdk/Elements";

export const Wrapper = styled.div`
    font-size: 16px;
`;

export const Hero = styled.div`
    position: relative;
    min-height: 150px;
    &:before {
        display: block;
        content: "";
        padding-top: 25%;
    }
    background-image: url("https://www.noritex.com/uplds/jpo73JjpaHvdfHTCtOficinas_de_Noritex.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: inset 0 -260px 100px -80px ${props => props.theme.primaryGradient};

    ${Title} {
        position: absolute;
        bottom: -5px;
        left: 0;
        width: 100%;
        text-align: center;
        color: ${props => props.theme.white};
        margin-bottom: 0;
        padding: 0;
        font-size: 90px;
        line-height: 80px;
    }
    @media (max-width: 980px) {
        & ${Title} {
            font-size: 70px;
            line-height: 60px;
        }
        box-shadow: inset 0 -170px 100px -80px ${props => props.theme.primaryGradient};
    }
    @media (max-width: 768px) {
        & ${Title} {
            font-size: 50px;
            line-height: 50px;
        }
    }
`;

export const Intro = styled(Subtitle)`
    max-width: 790px;
    color: ${props => props.theme.primaryDarkMode};
    margin: auto;
    text-align: center;
    margin: 30px auto !important;

    @media (max-width: 980px) {
        font-size: 32px;
    }
    @media (max-width: 768px) {
        font-size: 22px;
    }
`;

export const Cols = styled(Columns)`
    position: relative;
`;

export const alignRightMixin = css`
    text-align: right;
    padding-left: 0;
    padding-right: 3%;
`;

export const Text = styled.div`
    padding-left: 3%;
    p {
        margin: 0;
        padding: 0;
        font-size: 16px;
        line-height: 30px;
        color: ${props => props.theme.primaryDark};
        + p {
            margin-top: 30px;
        }
        @media (max-width: 768px) {
            font-size: 14px;
            line-height: 24px;
            + p {
                margin-top: 20px;
            }
        }
    }

    ${(props) => props.alignRight && alignRightMixin}
`;

export const Highlight = styled.h2`
    padding: 0;
    margin: 0;

    background-color: #112c55;
    color: #fff;
    font-size: 30px;
    padding: 25px 0;
    margin: 10% 0;
    position: relative;

    @media (max-width: 980px) and (min-width: 769px) {
        font-size: 24px;
    }

    @media (max-width: 768px) {
        margin: 20px 0;
    }
    @media (max-width: 480px) {
        font-size: 20px;
    }

    &:before,
    &:after {
        content: "";
        position: absolute;
        top: 0;
        height: 100%;
        background-color: #112c55;
        z-index: 2;
    }

    &:before {
        right: 100%;
        width: 4000px;
    }
    &:after {
        left: 100%;
        width: 150px;
    }
`;

const imagesResponsiveMixin = css`
    box-shadow: none;
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: center;
    @media (max-width: 480px) {
        height: 150px;
    }
`;

export const Image1 = styled.figure`
    img {
        display: inline-block;
        width: 80%;
        box-shadow: 0px 0px 0px 20px white;
    }
    text-align: right;
    margin-right: -60px;

    @media (max-width: 768px) {
        margin-right: 0;
        img {
            ${imagesResponsiveMixin}
        }
    }
`;

export const Image2 = styled.figure`
    img {
        display: block;
        width: 100%;
        box-shadow: 0px 0px 0px 20px white;
    }
    padding-left: 20px;

    ${Text} {
        @media (min-width: 769px) {
            padding-left: 70px;
            padding-top: 50px;
            max-width: 642px;
        }
    }
    ${Title} {
        margin-top: 10px;
        margin-bottom: 0;
    }

    @media (max-width: 768px) {
        padding-left: 0;
        img {
            ${imagesResponsiveMixin}
        }
        & ${Text} {
            padding: 20px;
        }
    }
`;

export const LeftCol = styled(Column)`
    z-index: 1;
    @media (max-width: 768px) {
        padding: 0;
        margin-top: 20px;
    }
`;

export const RightCol = styled(Column)`
    padding-right: 0;
    @media (min-width: 769px) {
        margin-top: -240px;
    }
    @media (max-width: 768px) {
        padding: 0;
    }
`;

export const ColsProducts = styled(Columns)`
    @media (max-width: 980px) {
        display: block;
    }
`;

export const WrapperProducts = styled(Section)`
    position: relative;
    &:before {
        content: "";
        display: block;
        background-color: #112c55;
        position: absolute;
        height: calc(100% + 190px);
        left: 0;
        width: 50%;
        top: -160px;
    }

    @media (max-width: 980px) {
        &:before {
            display: none;
        }
        background-color: #112c55;
    }
`;

export const TextProducts = styled(Column)`
    width: 400px;
    z-index: 1;
    padding-top: 30px !important;
    p {
        margin: 0;
        padding: 0;
        font-size: 16px;
        line-height: 30px;
        color: white;
        + p {
            margin-top: 30px;
        }
    }
    h2 {
        white-space: nowrap;
        color: white;
    }
    h3 {
        color: white;
    }
    @media (max-width: 980px) {
        width: 100%;
        h2 {
            white-space: initial;
            font-size: 40px;
        }
    }

    @media (max-width: 768px) {
        p {
            font-size: 14px;
            line-height: 24px;
            + p {
                margin-top: 10px;
            }
        }
        h2 {
            font-size: 30px;
        }
        h3 {
            font-size: 18px;
        }
    }
`;

export const ImageProducts = styled.figure`
    position: relative;
    background-color: #000;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -10px;
        width: 20px;
        height: 100%;
        background-color: ${props => props.theme.white};
        z-index: 1;
        @media (max-width: 980px) {
            background-color: #112c55;
        }
    }
    img {
        width: 100%;
        display: block;
        opacity: 0.9;
    }

    @media (max-width: 980px) {
        margin: 0px 20px;
    }
`;

export const WrapperFeatures = styled(Section)`
    padding-top: 7% !important;
`;

export const TextFeatures = styled(Column)`
    flex-basis: 50%;
    &:last-child {
        padding-left: 7%;
    }

    &:first-child {
        padding-right: 7%;
    }

    ${Title} {
    }
    p {
        margin: 0;
        padding: 0;
        max-width: 420px;
        font-size: 16px;
        line-height: 30px;
        color: ${props => props.theme.primaryDark};
        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }
    a {
        color: #464646;
        font-size: 20px;
        text-decoration: none;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
`;

export const ColFeatures = styled(Column)`
    flex-basis: 50%;
`;

export const Feature = styled(Columns)`
    align-items: center;
    &:not(:last-child) {
        margin-bottom: 5%;
    }
    &:nth-child(odd) {
        flex-direction: row-reverse;
        & ${TextFeatures} {
            padding-left: 6%;
        }
        & ${ColFeatures} {
            @media (min-width: 769px) {
                padding-right: 0;
            }
        }
    }

    &:nth-child(even) {
        & ${Text} {
            padding-right: 6%;
        }
        & ${ColFeatures} {
            @media (min-width: 769px) {
                padding-left: 0;
            }
        }
    }

    figure {
        position: relative;
        img {
            width: 100%;
            display: block;
        }
        span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 70px;
            height: 70px;
            line-height: 75px;
            text-align: center;
            background-color: #fff;
            box-shadow: 0 24px 46px -2px rgba(0, 0, 0, 0.28);
            border-radius: 100%;
            cursor: pointer;
            opacity: 0.9;
            &:hover {
                opacity: 1;
            }
            svg {
                color: #333;
                width: 18px;
                height: 18px;
            }
        }
    }
`;

export const VideoFeatures = styled(Modal)`
    z-index: 302;
    > div {
        max-width: 800px;
    }
    iframe {
        width: 100%;
        height: 500px;
        @media (max-width: 768px) {
            height: 300px;
        }
    }
`;
