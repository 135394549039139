import React from "react";
import Blueberry from "../../../blueberry/index";
import { Helmet } from "react-helmet";
import ProductsSectionTemplate from "../../templates/ProductsSectionTemplate";
import withTracker from "../../../blueberry/connectPromise";
import { Wrapper } from "./style";
import { Section, Container } from "../../sdk/Layout";
import InternalLink from "../../components/InternalLink";
import Hero from "./components/Hero";
import Historia from "./components/Historia";
import Features from "./components/Features";
import NuestrosProductos from "./components/NuestrosProductos";
import lang_es from "./lang/lang_es";
import lang_en from "./lang/lang_en";
import lang_pt from "./lang/lang_pt";
import SEOFieldsHelmet from "../../utils/SEOFieldsHelmet";

function getLang(lang) {
    switch (lang) {
        case "es":
            return lang_es;
        case "en":
            return lang_en;
        case "pt":
            return lang_pt;
    }
}

class Page extends ProductsSectionTemplate {
    state = {};

    static async getInitialData(props) {
        // const token = props.initialToken ? props.initialToken : (props.token ? props.token.token : "")
        let { lang } = props.match.params;

        const slugF = "history";

        return new Promise((resolve, reject) => {
            return Blueberry.page({ slug: slugF, lang })
                .then((resp) => {
                    if (
                        resp &&
                        resp.customFields &&
                        resp.customFields.ambientBlock &&
                        resp.customFields.ambientBlock.length > 0
                    ) {
                        Blueberry.collectionChildren({
                            limit: 6,
                            slug: resp.customFields.ambientBlock[0].slug,
                        }).catch((err) => {});
                    }

                    if (
                        resp &&
                        resp.customFields &&
                        resp.customFields.recomendedProducts &&
                        resp.customFields.recomendedProducts !== ""
                    ) {
                        Blueberry.itemToUser({
                            limit: 25,
                            filter: resp.customFields.recomendedProducts,
                        }).catch(() => {});
                    }
                    resolve(resp);
                })
                .catch((err) => {
                    resolve({
                        title: "Historia - Noritex",
                        content: "",
                        protected: true,
                        status: "Historia",
                        seo: { title: "", description: "" },
                    });
                });
        });
    }

    gotoThankYouPage = () => {
        const { lang } = this.props.match.params;

        switch (lang) {
            case "es":
                this.props.history.push(`/es/gracias-por-registrarse-full/`);
                break;
            case "en":
                this.props.history.push(`/en/gracias-por-registrarse-full/`);
                break;
            case "pt":
                this.props.history.push(`/pt/gracias-por-registrarse-full/`);
                break;
        }
    };

    removeHtml(html) {
        return html ? html.replace(/<\/?[^>]+(>|$)/g, "").trim() : "";
    }

    renderBody() {
        const { lang } = this.props.match.params;

        const langJson = getLang(lang);

        const { data } = this.props;

        return (
            <>
                <SEOFieldsHelmet
                    seo={data ? data.seo : null}
                    lang={lang}
                    defaultTitle={data ? data.title : null}
                    defaultDescription={data ? data.description : null}
                />
                <Wrapper>
                    <Hero langJson={langJson}></Hero>
                    <Historia langJson={langJson}></Historia>
                    <NuestrosProductos langJson={langJson}></NuestrosProductos>
                    <Features langJson={langJson}></Features>
                </Wrapper>
            </>
        );
    }
}

export default withTracker()(Page);

// export default withSSR(Page);
