import styled, { css } from "styled-components";
import { Control } from "../sdk/Form";
import { Tabs, Pagination, Preloader } from "../sdk/Elements";
import { Title } from "../sdk/Layout";
import { Buttons } from "../sdk/Button";

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${Title} {
        margin-bottom: 0;
    }

    @media (max-width: 480px) {
        flex-direction: column;
        ${Title} {
            margin-bottom: 20px;
        }
    }
`;
export const ButtonsExtended = styled(Buttons)`
    margin: 30px 0;
`;

const SearcherMixin = css`
    input {
        height: 34px;
        font-size: 14px;
    }
    .icon {
        top: 8px;
        right: 8px;
    }
`;

export const Searcher = styled(Control)`
    position: relative;
    width: 100%;
    input {
        padding-right: 30px;
    }
    .icon {
        position: absolute;
        top: 12px;
        right: 10px;
        svg {
            width: 16px;
            color: ${(props) => props.theme.gray};
        }
    }
    ${(props) => props.small && SearcherMixin};
`;

export const AccountInfo = styled.div`
    display: flex;
    gap: 1%;
    margin-top: 50px;
    .info {
        border: solid 1px ${(props) => props.theme.borderColor};
        border-radius: 3px;
        padding: 10px;
        width: 100%;
        span {
            font-size: 14px;
            /* font-weight: bold; */
            color: ${(props) => props.theme.gray};
        }
        div {
            font-size: 20px;
            margin-top: 5px;
        }
    }

    @media (max-width: 980px) {
        flex-wrap: wrap;
        justify-content: center;
        .info {
            width: 19.2%;
        }
    }
    @media (max-width: 768px) {
        margin-top: 20px;
        .info {
            width: 49.5%;
            margin-bottom: 1%;
            div {
                font-size: 18px;
            }
            &:nth-of-type(6):last-child {
                width: 100%;
            }
        }
    }
`;

export const AccountPicker = styled.div`
    width: 300px;
    flex: none;
    position: relative;
    user-select: none;
    @media (max-width: 980px) {
        width: 100%;
        margin-bottom: 10px;
    }
    .wrapper {
        background-color: ${(props) => props.theme.grayLight};
        display: flex;
        align-items: center;
        padding: 10px;
        border-radius: 3px;
        position: relative;
        cursor: pointer;
        height: 100%;
    }
    .arrow {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        svg {
            width: 10px;
            color: ${(props) => props.theme.gray};
        }
    }
    .profile {
        flex: none;
        width: 50px;
        height: 50px;
        border: solid 1px ${(props) => props.theme.borderColor};
        background-color: #fff;
        padding: 10px;
        border-radius: 100%;
        margin-right: 10px;
        svg,
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .title {
        font-weight: bold;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 30px;
    }

    .account-list {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        padding-top: 5px;
        ul {
            li {
                a {
                    cursor: pointer;
                    padding: 10px;
                    border-radius: 3px;
                    color: ${(props) => props.theme.primaryDark};
                    display: block;
                    position: relative;
                    &:hover {
                        background-color: ${(props) => props.theme.grayLight};
                    }
                    &.is-checked {
                        &:before {
                            content: "";
                            display: block;
                            width: 6px;
                            height: 12px;
                            border-width: 2px;
                            border-bottom-style: solid;
                            border-right-style: solid;
                            border-color: ${(props) => props.theme.primary};
                            transform: rotate(40deg);
                            position: absolute;
                            top: 50%;
                            margin-top: -9px;
                            right: 13px;
                        }
                    }
                }
            }
        }
    }
`;

export const TabsExtended = styled(Tabs)`
    margin: 50px 0;
    margin-bottom: ${props => props.marginBottom || 50}px;
    ul {
        width: 100%;
    }
    @media (max-width: 768px) {
        margin: 30px 0;
    }
`;

export const TableOptions = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    @media (max-width: 480px) {
        flex-direction: column-reverse;
        div {
            width: 100%;
        }
    }
`;

export const TabsFilter = styled(Tabs)`
    height: 34px;
    font-size: 14px;
    margin-right: 10px;
    ul {
        li {
            &:not(:first-child):not(:only-child) {
                margin-left: -1px;
            }
            &:only-child {
                margin-left: 0 !important;
            }
            a {
                color: ${(props) => props.theme.gray} !important;
            }
            &.is-active {
                a {
                    background-color: ${(props) =>
                        props.theme.grayLight} !important;
                    border-color: ${(props) =>
                        props.theme.borderColor} !important;
                    color: ${(props) => props.theme.primaryDark};
                }
            }
        }
    }

    @media (max-width: 480px) {
        margin-top: 10px;
        li {
            width: 100%;
        }
    }
`;

export const Table = styled.div`
    width: 100%;
    .h-scroll {
        overflow-x: auto;
    }
    table {
        width: 100%;
        border-collapse: collapse;
        border: solid 1px ${(props) => props.theme.graySoft};
        tr {
            &:not(:last-child) {
                border-bottom: solid 1px ${(props) => props.theme.graySoft};
            }
            th,
            td {
                text-align: left;
                padding: 10px;
                font-size: 14px;
            }
            th {
                padding: 10px;
                font-weight: bold;
                color: ${(props) => props.theme.gray};
                white-space: nowrap;
            }
            td {
                padding: 15px 10px;
            }
        }

        thead {
            tr {
                th {
                    background-color: ${(props) => props.theme.graySoft};
                }
            }
        }

        tbody {
            tr:nth-child(even) {
                /* background-color: ${(props) => props.theme.grayLight}; */
            }
        }
    }
`;

export const PaginationExtended = styled(Pagination)`
    ul {
        font-size: 14px;
    }
`;

const MultilineMixin = css`
    flex-direction: column;
    div + div {
        margin-top: 10px !important;
    }
`;
export const Skeleton = styled.div`
    display: flex;
    gap: 1%;
    width: 100%;
    min-width: 200px;

    ${(props) => props.multiline && MultilineMixin};
    div {
        width: 100%;
        height: ${(props) => props.height}px;
        margin-top: 0 !important;
        border-radius: 3px;
    }
    & + & {
        margin-top: 20px;
    }
`;

export const LoadingMessage = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 95%;
    max-width: 450px;
    z-index: 99;
    transform: translate(-50%, -50%);
    .wrapper {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: ${(props) => props.theme.gray};
        .loading {
            flex: none;
            border: none;
            margin-right: 10px;
        }
    }
`;
export const ErrorWrapper = styled.div`
    min-height: 100px;
    position: relative;
    max-width: 346px;
    width: 100%;
    margin: auto;
`;


export const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2 {
      margin-bottom: 0;
  }
  div {
      text-align: center;
  }

  @media (max-width: 480px) {
      flex-direction: column;
      div {
          width: 100%;
      }
      h2 {
          margin-bottom: 10px;
      }
      ${Searcher} {
          width: 100%;
      }
  }
`;

export const TrackingCell = styled.td`
    width: 300px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
        width: 94px;
        align-self: baseline;
    }
`;

export const ContainerSearcher = styled.div`
    min-width: 280px;
`;
