export default {
    superTitle: "Nosotros",
    title: "Historia Noritex",
    shortDescription: `Bienvenido a Noritex S.A, donde lideramos la tendencia y nos apasiona llevarla a nuestros clientes.`,
    since: "Noritex existe desde 1980",
    longStory1:
        "Nuestra historia comenzó en 1954 en la Zona Libre de Colón. Empezamos a comercializar líneas de vajillas y porcelana de uso doméstico, luego de nuestro crecimiento, dirigimos todos nuestros esfuerzos hacia diversificar nuestras líneas de productos.",
    longStory2:
        "Por eso en 1980 inicia Noritex, S.A, empresa líder en importación, re-exportación y distribución de artículos del hogar, religiosos, navidad, textiles y escolares, a lo largo y ancho del mercado latinoamericano. Con diversos desafíos,  hemos logrado traer lo último en tendencias para innovar en el mercado y hemos implementado las herramientas necesarias para volvernos más eficientes y ofrecerles la mejor y más completa oferta de productos",
    longStory3:
        "Nuestras marcas insignias, Concepts Life y Santini Christmas, cuentan con novedosas ferias cada año que incluyen, más de 30 colecciones y 45,000 referencias, trayendo siempre las últimas tendencias en decoración de hogar y navidad para que puedas ofrecerlas a tus clientes.",
    longStory4:
        "Nuestros productos son vendidos en toda América Latina y el Caribe por su referencia en moda, actualidad y tendencia.",

    productsTitle: "Nuestros Productos",
    productsHelp:
        "Noritex es conocido mundialmente por la diferenciación y variedad de productos.",
    productsHelp2:
        "Tenemos un equipo dedicado que desarrolla nuevos productos todos los días. Además, para darle las últimas tendencias, nuestras marcas cambian el catálogo completo cada tres meses.",
    products1:
        "Para nosotros es importante mantenernos a la vanguardia y actualizados, por esto, hemos desarrollado la opción de compra virtual, a través de nuestra página web de manera fácil y rápida  para la comodidad de todos nuestros clientes.",
    product2:
        "Esperamos que disfrute de nuestros productos tanto como nosotros disfrutamos crearlos y ofrecérselos.",
    products3: "¡Sea parte de este exitoso modelo!",

    features: [
        {
            title: "Logística avanzada",
            img: "https://www.noritex.com/PdpzChfc6vMePfkvFbodega.jpg",
            paragraph:
                "Contamos con la logística más avanzada de Latinoamérica con más de 100 personas dedicadas a brindarle todos sus productos a tiempo.",
        },
        {
            title: "Inversión en el medio ambiente",
            action: "modal",
            img:
                "https://www.noritex.com/eC2k6SBqTc8NZPYkmPaneles_Solares_Noritex.png",
            paragraph:
                "Nos dedicamos a nuestro medio ambiente, por eso hemos abierto dos parques solares y estamos produciendo el 80% de nuestro consumo eléctrico.",
        },
        {
            title: "Apoyo a nuestra comunidad",
            img:
                "https://www.noritex.com/LeiYgFXJfk5kQZTXTScreen_Shot_2020-11-05_at_1.27.30_PM.png",
            paragraph:
                "Creemos en ayudar a nuestra comunidad local a prosperar, por eso nos dedicamos a ayudar a crecer y revitalizar nuestra comunidad.",
        },
    ],
};
