export default {
	superTitle: "About Us",
	title: "Noritex History",
	shortDescription:
		"Welcome to Noritex, your trusted source for innovative household, religious, Christmas, textile, and school items. We keep our finger on the pulse of the latest trends to deliver top-quality products for your store",
	since: "Since 1980",
	longStory1:
		"Our journey began in 1954 in the Colon Free Zone of Panama, where we set our roots developing and manufacturing high-quality tableware and porcelain for customers both locally and internationally.",
	longStory2:
		"Established in 1980, Noritex S.A. has become a leading company in the import, re-export, and distribution of diverse product categories including household goods, religious items, Christmas decorations, textiles, and school supplies. Despite facing numerous challenges, we continue to push the boundaries of innovation through our investments in cutting-edge technology and market trend analysis.",
	longStory3:
		"Our flagship brands, Concepts Life and Santini Christmas, consistently introduce over 30 new collections and a staggering 45,000 new products annually. We're committed to staying ahead of home decor and Christmas decoration trends, providing you with fresh inspiration for your customers every year.",
	longStory4:
		"Our products are sold throughout the world for their reference in fashion",

	productsTitle: "Our Diverse Product Portfolio",
	productsHelp:
		"Our dedicated team works tirelessly to develop new and innovative products daily. ",
	productsHelp2:
		"To ensure we always offer the freshest trends, our brands revamp the entire catalog every quarter. Discover a world of unique products with Noritex.",
	products1:
		"At Noritex, we believe in leading the way in both fashion and technology. ",
	product2:
		"That's why we've invested in the development of our e-commerce platform, ensuring a seamless shopping experience for our customers. We take great joy in creating our products, and we hope that you find as much delight in using them.",
	products3: "Register now and become part of our family!",

	features: [
		{
			title: "Superior Logistics",
			img: "https://www.noritex.com/PdpzChfc6vMePfkvFbodega.jpg",
			paragraph:
				"With a team of over 100 dedicated professionals, we boast one of the most efficient logistics networks in Latin America. Our commitment is to ensure timely delivery of all your ordered products",
		},
		{
			title: "Commitment to Sustainability",
			action: "modal",
			img: "https://www.noritex.com/eC2k6SBqTc8NZPYkmPaneles_Solares_Noritex.png",
			paragraph:
				"We deeply care about our planet, which is why we've established two solar farms that now produce 80% of our energy needs. This reflects our dedication to environmental sustainability and clean energy.",
		},
		{
			title: "Community Engagement",
			img: "https://www.noritex.com/LeiYgFXJfk5kQZTXTScreen_Shot_2020-11-05_at_1.27.30_PM.png",
			paragraph:
				"We strongly believe in giving back to our local community. By investing in growth and revitalization initiatives, we strive to contribute to a thriving community that benefits all its members.",
		},
	],
};
